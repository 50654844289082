.boat-type-icon {
    width: 40px;
    height: 40px;
}

.boat-list {
    .ant-table-empty {
        margin-bottom: 64px;
    }
}

.ant-popover .count-outings {
    > div {
        margin: 8px 0;
    }
    > div:first-child {
        margin-top: 0;
    }
    > div:last-child {
        margin-bottom: 0;
    }
    label {
        width: 90px;
        display: inline-block;
        margin-right: 8px;

        &::after {
            content: ':';
            float: right;
        }
    }
}
