@import '../../styles/_settings.scss';

.user-list {
    .__filters {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 8px;
        margin-bottom: 16px;

        .ant-select {
            width: 100%;
        }
        > .ant-btn {
            margin-top: 22px;
        }

        .__filter {
        }
    }

    .--request-pending {
        color: $secondary-color;
    }
}

@media (max-width: 1400px) {
    .user-list {
        .__filters {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}

@media (max-width: 900px) {
    .user-list {
        .__filters {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 500px) {
    .user-list {
        .__filters {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}

