.country-select {
    width: 100%;

    .__content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    margin: 0;
    padding: 0 8px;

    .__flag {
        width: 28px;
        height: 28px;
        margin-right: 4px;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
        }
    }
}

.ant-input-group-addon {
    .country-select {
        border: none;
        height: 30px;
        background: none;
    }
}

.country-select-dropdown {
    border: 1px solid #eee;
    border-radius: 4px;

    .__option {
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #eee;
        padding: 4px 8px;
        text-align: left;
        img {
            width: 28px;
            margin-right: 4px;
        }
    }

    .__content {
        display: flex;
        flex-direction: column;
        max-height: 200px;
        overflow-x: hidden;
        overflow-y: auto;
    }
}
