.owner-detail {
    .main-info {
        display: flex;
        margin-bottom: 8px;
        .name {
            width: 50%;
        }
    }
    .contact-info {
        .field {
            width: 100% !important;
        }
    }
}
