.table-column-filter-dropdown {

    .ant-menu {
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .list-row {
        padding: 0 8px;
    }
}
