body > .app-container {
    min-height: 100%;
    height: 100%;
}

@import 'mixins';

@import 'settings';

@import './antd/antd';

@import 'utils';
@import 'layout';
@import 'forms';

@import 'notificationBell';

@import './pages/login';
@import './pages/home';
@import './pages/boatList';
@import './pages/showBoatModal';
@import './pages/calendar';

@import './components/slider';
@import './components/displayForm';
@import './components/loading';
@import './components/panel';
@import './components/company/companyForm';
@import './components/permission/roleForm';
@import './components/tableColumnFilter';
@import './components/charts';
@import './components/rollingWeeksTable';
@import './components/svgMap';
