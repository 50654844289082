.tutorials {
    position: relative;
    .__loading {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;

        top: 30px;
        left: 0;
        bottom: 0;
        right: 0;
        background: rgba(#ffffff, 0.5);
    }
    .__tutorial {
        position: relative;
        .__header {
            display: flex;
            align-items: center;
            margin: 16px 0 8px 0;
            .__title {
                margin: 0;
                margin-right: 16px;
            }
        }
        .__slides {
            display: flex;
            align-items: center;
            .__slide {
                display: flex;
                flex-direction: column;
                margin-right: 16px;
                height: 280px;
                width: 160px;
                .__image {
                    width: 100%;
                    height: calc(100% - 40px);
                    object-fit: contain;
                }
                .__actions {
                    display: flex;
                    justify-content: space-between;
                    padding: 4px 8px;
                    margin-bottom: 4px;
                    opacity: 0.5;
                    border-bottom: 1px solid #ffffff;
                    .__colorpicker {
                        z-index: 999;
                        background: none;
                        border: 1px solid white;
                        padding: 0;
                        width: 50px;
                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                &:hover {
                    .__actions {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
