input[readonly],
textarea[readonly] {
    background-color: #f5f5f5;
}
.ant-radio-button-wrapper-checked,
.ant-radio-button-wrapper-checked:hover {
    border-color: $primary-color !important;
}
.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $primary-color !important;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: $primary-color !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: #f5f5f5;
}
.ant-radio-disabled.ant-radio-checked .ant-radio-inner {
    background-color: #f5f5f5;
}
.ant-radio-disabled.ant-radio-checked .ant-radio-inner::after {
    background-color: darken($primary-color, 10%);
}

.ant-picker {
    width: 100%;
    background: #fdfdfd;
    input {
        background: transparent;
    }
}

.ant-form-item {
    margin-bottom: 8px;

    .label-option-mark {
        display: inline-block;
        margin-left: 8px;
        color: #c1c1c1;
        font-size: 10px;
    }
}
