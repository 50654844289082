@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: url('fonts/TitilliumWeb/TitilliumWeb-Light.woff') format('woff'),
         url('fonts/TitilliumWeb/TitilliumWeb-Light-latin.woff2') format('woff2');
}
@font-face {
    font-family: 'Titillium Web';
    font-style: italic;
    font-weight: 300;
    src: url('fonts/TitilliumWeb/TitilliumWeb-LightItalic.woff') format('woff'),
         url('fonts/TitilliumWeb/TitilliumWeb-LightItalic-latin.woff2') format('woff2');
}
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: url('fonts/TitilliumWeb/TitilliumWeb-Regular.woff') format('woff'),
         url('fonts/TitilliumWeb/TitilliumWeb-Regular-latin.woff2') format('woff2');
}
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: url('fonts/TitilliumWeb/TitilliumWeb-SemiBold.woff') format('woff'),
         url('fonts/TitilliumWeb/TitilliumWeb-SemiBold-latin.woff2') format('woff2');
}
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: url('fonts/TitilliumWeb/TitilliumWeb-Bold.woff') format('woff'),
         url('fonts/TitilliumWeb/TitilliumWeb-Bold-latin.woff2') format('woff2');
}
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 900;
    src: url('fonts/TitilliumWeb/TitilliumWeb-Black.woff') format('woff'),
         url('fonts/TitilliumWeb/TitilliumWeb-Black-latin.woff2') format('woff2');
}
