// Login
.login-page {
    height: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #3eb7ec;

    > div {
        background: rgba($white, .9);
        padding: 16px 36px 36px 36px;
    }
    a.reset-password {
        text-align: center;
        display: block;
    }
}
.login-form-button {
    width: 100%;
}
