$table-border-color: #e9e9e9;
$table-header-background: #f7f7f7;


.ant-table thead > tr > th,
.ant-table tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
    padding: 8px 16px;
    white-space: nowrap;
}

.ant-table-spin-holder > .anticon {
    margin-top: 50px;
    font-size: 24px;
    color: $secondary-color;
}

.row-clickable .ant-table-tbody .ant-table-row {
    &:hover {
        cursor: pointer;
    }
    &:active {
        background: rgba($light-secondary-color, 0.5);
        transition: all 0;
    }
}

.ant-table {
    table {
        border: 1px solid #e9e9e9;
        border-bottom: none;
    }
    .align-right {
        text-align: right;
    }
}

.ant-table-scroll > .ant-table-header {
    overflow: hidden !important;
    padding-bottom: 20px !important;
    padding-right: 16px;
}
.ant-table-scroll > .ant-table-footer {
    padding-right: 26px;
}


.ant-table-footer {
    padding: 8px;
}

.ant-table-tbody {
    background: #fff;

    > tr:hover > td {
        background: none;
    }

    > tr:hover {
        background: rgba($light-secondary-color, 0.2);
    }

    > tr {
        > td,
        > th {
            border-bottom: 1px solid $table-border-color;
            position: relative;

            &.--with-link {
                padding: 0;
                 > a {
                     display: block;
                     padding: 8px 16px;
                 }
            }
        }

        > th {
            font-weight: normal;
            color: rgba($black, 0.9);
        }
    }
}
// DataTable

.ant-pagination {
    &-item,
    &-next,
    &-prev {
        &:hover {
            border-color: $primary-color;
        }

        &:hover a {
            color: $primary-color;
        }

        &-active {
            background-color: $primary-color;
            border-color: $primary-color;
        }

        &-active a,
        &-active:hover a {
            color: #fff;
        }
    }

    &-disabled:hover {
        border-color: #d9d9d9;

        a {
            color: rgba(0, 0, 0, 0.25);
        }
    }
}

.ant-table-tbody .table-subtitle {
    background-color: $primary-color;
    text-transform: uppercase;
    text-align: center;
    padding: 0;
}

table td.disabled,
table th.disabled {
    background: rgba($medium-gray, 0.5) !important;
}

table .editable-cell {
    &:not(.disabled):hover {
        background: rgba($light-secondary-color, 0.1);
        cursor: pointer;
    }

    &:not(.disabledConge):hover {
        background: rgba($light-secondary-color, 0.1);
        cursor: pointer;
    }

    &:not(.disabled):active {
        background: rgba($light-secondary-color, 0.2);
    }

    &:not(.disabledConge):active {
        background: rgba($light-secondary-color, 0.2);
    }
}

.ant-table tbody .number-column,
.ant-table tbody .number-column input {
    text-align: right;
}

table tr.success > td,
table tr.success > th,
table th.success,
table td.success {
    background: rgba($success-color, 0.5);
}
