// Settings of the Application Style

@import 'font_TitilliumWeb';

$font-family: 'Titillium Web', 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;

$primary-color: #f59e00;
$secondary-color: #59c1c6;
$light-secondary-color: #59c1c6;
$danger-color: #f04134;
$success-color: #29b55c;
$info-color: #287fb5;

$black: #1e2942;
$white: #fdfdfd;
$dark-gray: #505050;
$medium-gray: #a5a5a5;
$light-gray: #d0d0d0;

$font-color: #333;

$page-background: $white;

$sidebar-background: #001529;
$sidebar-selected-item-background: #59c1c6;
$sidebar-footer-background: #002140;
$sidebar-sub-menu-background: #002140;

$header-background: $sidebar-background;
$header-color: $black;
