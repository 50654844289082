.absence-calendar {
    .absence-planning-day {
        height: 25px;
        width: 100%;

        &.no-working {
            background: rgba($black, 0.2);
        }

        &.approved {
            background: $success-color;
        }
        &.pending {
            background: rgba($success-color, 0.5);
        }
        &.anticipated-return {
            text-align: center;
            color: $danger-color;
            background: rgba($danger-color, 0.5);
        }

        .count-absences-trigger {
            width: 100%;
        }
    }

    .ant-table .ant-table-tbody td {
        padding: 0;
        line-height: 25px;
    }

    .legends {
        display: flex;
        margin-top: 8px;
        margin-left: -16px;

        > div {
            margin-left: 16px;
            padding-bottom: 4px;
            border-bottom: 2px solid transparent;
        }
        > div:hover {
            cursor: pointer;
            border-bottom-color: $secondary-color;
        }
        > div.selected {
            border-bottom-color: $primary-color;
        }

        .legend-thumbnail {
            height: 20px;
            width: 50px;
            display: inline-block;
            margin: 0 8px -5px 0;

            &.approved {
                background: $success-color;
            }
            &.pending {
                background: rgba($success-color, 0.5);
            }
            &.present {
                background: $white;
                border: 1px solid #e9e9e9;
            }
            &.anticipated-return {
                text-align: center;
                color: $danger-color;
                background: rgba($danger-color, 0.5);
            }
        }
    }
}
